import * as React from "react";

import { classnames } from "utils/classnames";

const classes = {
  normal: "bg-white/20 border-white",
  success: "bg-kermit-green/20 border-kermit-green",
  warning: "bg-marigold/20 border-marigold",
  error: "bg-coral/20 border-coral",
};

interface IMessageProps {
  type?: "normal" | "success" | "warning" | "error";
  children: React.ReactNode;
  className?: string;
}

const Message = ({ type = "normal", children, className }: IMessageProps) => {
  return (
    <div className={classnames("border-l-4 px-5 py-4 mb-4 last:mb-0", classes[type], className)}>
      {children}
    </div>
  );
};

export default Message;
